$(() => {
  if (jQuery.browser.safari) {
    $('#menu').addClass('safari');
  }

  // Touchscreen
  $('#menu .dropdown > .dropdown-toggle').on('touchstart', function (e) {
    if (window.innerWidth > 768) {
    const $dropdown = $(this).closest('.dropdown');
    if ($dropdown.hasClass('show')) {
      return; // allow link to be clicked
    }
    $('#menu .dropdown, #menu .dropdown-menu').removeClass('show'); // deactivate  all
    $dropdown.toggleClass('show'); // show just this
    $dropdown.find('.dropdown-menu').addClass('show');
    e.preventDefault();
    }
  });

  // Touchscreen click elsewhere
  $(document).on('touchstart', (e) => {
    const $target = $(e.target);
    const touchIgnore = [
      '.dropdown-toggle',
      '.megamenu-link',
      '.dropdown-item',
    ];

    if ($target.is(touchIgnore.join(','))) return;

    if ($target.closest('.nav-main-subnav').length) return;

    const $dropdown = $('#menu .dropdown');
    $dropdown.removeClass('show');
    $dropdown.find('.show').removeClass('show');
  });
});
